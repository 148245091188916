
























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Topic from '@improve/common-utils/src/model/Topic';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';
import lightbulbIcon from '../../assets/lightbulb.svg';
import union from '../../assets/union.svg';
import BaseMenu from './BaseMenu.vue';
import StatsBlock from './StatsBlock.vue';

@Component({
  name: 'BaseTopicCard',
  components: {
    BaseMenu,
    StatsBlock
  }
})
export default class BaseTopicCard extends Vue {
  @Prop({ default: null }) readonly topic!: Topic;

  @Prop({ default: null }) readonly numberOfExperts!: number;

  @Prop({ default: null }) readonly numberOfTickets!: number;

  @Prop({ default: () => [] }) readonly menuOptions!: Array<MenuOption>;

  lightBulbIcon = lightbulbIcon;

  union = union;

  get isTitleLong(): boolean {
    return this.topic.metaData!.displayName!.length > 16;
  }
}
