
















































import Vue from 'vue';
import { debounce } from 'lodash';
import { ActionMethod } from 'vuex';
import { Action, Getter } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import Topic from '@improve/common-utils/src/model/Topic';
import BaseTextInput from '@improve/common-components/src/components/core/BaseTextInput.vue';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';
import BaseTopicCard from '@improve/common-components/src/components/widgets/BaseTopicCard.vue';
import StatsData from '@improve/common-utils/src/model/StatsData';
import TabContent from '@improve/common-components/src/components/helpers/TabContent.vue';

@Component({
  name: 'Topics',
  components: {
    TabContent,
    BaseTextInput,
    BaseButton,
    BaseTopicCard
  }
})
export default class Topics extends Vue {
  @Getter organizationTopics!: Array<Topic>;

  @Getter topicStatsById!: Map<string, StatsData>;

  @Action fetchTopicsByContext!: ActionMethod;

  @Action fetchTopicStatsByBatch!: ActionMethod;

  private search: string | null = null;

  private debounceSearchInput = debounce((event: string) => {
    this.search = event;
  }, 300);

  get filteredTopics(): Topic[] {
    if (!this.search || this.search.length < 3) {
      return this.organizationTopics;
    }
    return this.organizationTopics
      .filter((_) => _.metaData
        && _.metaData.displayName
        && _.metaData.displayName?.toLowerCase().indexOf(this.search!.toLowerCase()) >= 0);
  }

  async created(): Promise<void> {
    await this.fetchTopicsByContext();
    await this.fetchTopicStats();
  }

  getTopicStats(topicId: string): StatsData {
    return this.topicStatsById && this.topicStatsById.has(topicId)
      ? this.topicStatsById.get(topicId)!
      : new StatsData();
  }

  fetchTopicStats(): Promise<StatsData> {
    return this.fetchTopicStatsByBatch(this.organizationTopics);
  }

  editTopic(topic: Topic): void {
    this.$router.push({ name: 'EditTopic', params: { id: topic.id! } });
  }

  addTopic(): void {
    this.$router.push({ name: 'CreateTopic' });
  }
}
