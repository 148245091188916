













import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'StatsBlock'
})
export default class StatsBlock extends Vue {
  @Prop({ default: null }) readonly icon!: string;

  @Prop({ default: null }) readonly data!: string;

  @Prop({ default: 17 }) readonly iconHeight!: number;

  @Prop({ default: 22 }) readonly iconWidth!: number;
}
