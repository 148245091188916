



















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import analyticsService from '@improve/common-utils/src/services/analyticsService';
import User from '@improve/common-utils/src/model/User';
import { Getter } from 'vuex-class';
import Organization from '@improve/common-utils/src/model/Organization';

@Component({
  name: 'BaseButton',
  inheritAttrs: false
})
export default class BaseButton extends Vue {
  @Prop({ default: '' }) readonly title!: string;

  @Prop({ default: '' }) readonly customId!: string;

  @Getter currentUser?: User;

  @Getter currentOrganization!: Organization;

  get isDepressed(): boolean {
    return Object.prototype.hasOwnProperty.call(this.$attrs, 'depressed');
  }

  get elevation(): string {
    return this.isDepressed ? '' : '0';
  }

  get buttonId(): string {
    return this.customId || `base-button-${this.title?.replace(/\s+/g, '-').toLowerCase()}`;
  }

  trackEvent(): void {
    analyticsService.trackClickEvent(this.title, this.$route.name!, this.currentUser!,
      this.currentOrganization);
  }
}
